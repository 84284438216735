import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PublicWorks = () => (
  <Layout>
    <SEO title="Public Works" />

    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Public Works</h1>
        <p>
          If you are looking to partner with a company that will help your
          public works projects to be completed on time, to be on-budget, and to
          follow local regulations to the letter - then Gray Hawk Land Solutions
          is ready to be of service.
        </p>
        <p>
          We work with State, Federal, and DOT clients to smoothly solve their
          Right-of-Way concerns. Our team is equipped with years of knowledge
          and expertise to minimize the risk and solve the minor or major
          hiccups of a project. We invite you to leverage our top-notch
          managerial oversight and quality assurance measures to find the most
          optimal land solutions for your projects.
        </p>
      </div>
    </div>
  </Layout>
)

export default PublicWorks
